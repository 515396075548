import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

   event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';
    
    return(
      <Paper>
        <form onSubmit={this.onSubmit}>
          <FormControl margin="normal" required>
            <InputLabel htmlFor="passwordOne">Password</InputLabel>
            <Input id="passwordOne" name="passwordOne" autoFocus type="password" onChange={this.onChange}/>
          </FormControl>
          <FormControl margin="normal" required>
            <InputLabel htmlFor="passwordTwo">Password</InputLabel>
            <Input id="passwordTwo" name="passwordTwo" type="password" onChange={this.onChange}/>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isInvalid}>
            Update My Password
          </Button>

          {error && <p>{error.message}</p>}
        </form>
      </Paper>
    );
  }
}

export default withFirebase(PasswordChangeForm);
