import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  Paper,
  Typography,
} from '@material-ui/core';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const PasswordForgetPage = () => (
  <div>
      <h1>PasswordForget</h1>
      <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
    email: '',
    error: null,
};

class PasswordForgetFormBase extends Component {
    constructor(props) {
      super(props);

      this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
      const { email } = this.state;
      this.props.firebase
        .doPasswordReset(email)
        .then(() => {
          this.setState({ ...INITIAL_STATE });
        })
        .catch(error => {
          this.setState({ error });
        });
        event.preventDefault();
    };

    onChange = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    render() {
      const { email, error } = this.state;
      const isInvalid = email === '';
      return (
        <Paper>
          <form onSubmit={this.onSubmit}>
            <FormControl margin="normal" required>
              <InputLabel htmlFor="email">Email Address</InputLabel>
              <Input id="email" name="email" autoComplete="email" autoFocus onChange={this.onChange} />
            </FormControl>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isInvalid}>
              Reset My Password
            </Button>
            {error && <p>{error.message}</p>}
          </form>
        </Paper>
      );
    }
}

const PasswordForgetLink = () => (
  <p>
      <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
