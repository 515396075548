import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

class AdminPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  

  render() {
    const { users, loading } = this.state;

    return(
      <div>
        <h1>Admin</h1>

        {loading && <div>Loading...</div>}
        <Switch>
          <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
          <Route exact path={ROUTES.ADMIN} component={UserList} />
        </Switch>
      </div>
    );
  }
}

class UserListBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }
  
  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe = this.props.firebase
      .users()
      .onSnapshot(snapshot => {
        let users = [];

        snapshot.forEach(doc =>
          users.push({ ...doc.data(), uid: doc.id }),
        );
        
        this.setState({
          users,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { users, loading } = this.state;

    return(
      <div>
        <h2>Users</h2>
        {loading && <div>Loading...</div>}
        <ul>
          {users.map(user => (
            <li key={user.uid}>
              <span>
                <strong>ID:</strong> {user.uid}
              </span>
              <span>
                <strong> eMail:</strong> {user.email}
              </span>
              <span>
                <strong> Username:</strong> {user.username}
              </span>
              <span>
                <Link 
                  to={{
                    pathname: `${ROUTES.ADMIN}/${user.uid}`, 
                    state: {user },
                  }}
                >
                  Details
                </Link>
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const UserList = withFirebase(UserListBase);

class UserItemBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      ...props.location.state,
    };
  } 

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
  };

  componentDidMount() {
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscript = this.props.firebase
      .user(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
          user: snapshot.data(),
          loading: false,
        });
      });
  }

  componentDidUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  render() {
    const { user, loading } = this.state;

    return(
      <div>
        <h2>User ({ this.props.match.params.idr})</h2>
        {loading && <div>Loading...</div>}

        {user && (
          <div>
            <span>
              <strong>ID:</strong> {user.uid}
            </span>
            <span>
              <strong> eMail:</strong> {user.email}
            </span>
            <span>
              <strong> Username:</strong> {user.username}
            </span>
            <span>
              <button type="button" onClick={this.onSendPasswordResetEmail}>
                Send Password Reset
              </button>
            </span>
          </div>
        )}
      </div>
    );
  }
}

const UserItem = withFirebase(UserItemBase);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];
  
export default compose(
  withAuthorization(condition),
  withFirebase,
  withEmailVerification,
)(AdminPage);
