import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyC6m7dExt4d5F4zTrhru_fo8TbrHkr1_Ws",
  authDomain: "collector-a2a92.firebaseapp.com",
  databaseURL: "https://collector-a2a92.firebaseio.com",
  projectId: "collector-a2a92",
  storageBucket: "collector-a2a92.appspot.com",
  messagingSenderId: "151442556470",
  appId: "1:151442556470:web:c6e36592a4a704a9"
};

class Firebase {
  constructor() {
    app.initializeApp(firebaseConfig);
    this.fieldValue = app.firestore.FieldValue;
    
    this.auth = app.auth();
    this.db = app.firestore();

    this.emailAuthProvider = app.auth.EmailAuthProvider;

    this.googleProvider = new app.auth.GoogleAuthProvider();
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });
  // *** User API ***
  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');

  // *** Collectible API ***
  collectible = uid => this.db.doc(`collectibles/${uid}`);

  collectibles = () => this.db.collection('collectibles');

  // *** Merge Auth and DB User API ***
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();

            // default empty roles
            if (!dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
   });
}


export default Firebase;
