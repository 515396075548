import React, { Component } from 'react';
import { compose } from 'recompose';

import {
  Button,
  List,
  ListItem,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
} from '@material-ui/core';

import { PasswordForgetForm } from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';

const SIGN_IN_METHODS = [
  {
    id: 'password',
    provider: null,
  },
  {
    id: 'google.com',
    provider: 'googleProvider',
  },
];

const AccountPage = () => (
  <AuthUserContext.Consumer>
    {authUser => (
       <div>
         <h1>Account: { authUser.email } </h1>
         <PasswordForgetForm/>
         <PasswordChangeForm/>
         <LoginManagement authUser={authUser}/>
       </div>
    )}
  </AuthUserContext.Consumer>
);

class LoginManagementBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSignInMethods: [],
      error: null,
    };
  }

  componentDidMount() {
    this.fetchSignInMethods();
  }

  fetchSignInMethods() {
    this.props.firebase.auth
      .fetchSignInMethodsForEmail(this.props.authUser.email)
      .then(activeSignInMethods =>
        this.setState({ activeSignInMethods, error: null }),
      )
      .catch(error => this.setState({ error }));
  }

  onDefaultLoginLink = password => {
    const credential = this.props.firebase.emailAuthProvider.credential(
    this.props.authUser.email,
    password,
    );
    this.props.firebase.auth.currentUser
    .linkAndRetrieveDataWithCredential(credential)
    .then(this.fetchSignInMethods)
    .catch(error => this.setState({ error }));
  };

  onSocialLoginLink = provider => {
    this.props.firebase.auth.currentUser
      .linkWithPopup(this.props.firebase[provider])
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  onUnlink = providerId => {
    this.props.firebase.auth.currentUser
      .unlink(providerId)
      .then(this.fetchSignInMethods)
      .catch(error => this.setState({ error }));
  };

  render() {
    const { activeSignInMethods, error } = this.state;

    return (
      <div>
        Sign In Methods:
        <List>
          { SIGN_IN_METHODS.map(signInMethod => {
              const onlyOneLeft = activeSignInMethods.length === 1;
              const isEnabled = activeSignInMethods.includes(
                signInMethod.id,
              );

              return(
                <ListItem key={signInMethod.id}>
                  {signInMethod.id === 'password' ? (
                      <DefaultLoginToggle
                        onlyOneLeft={onlyOneLeft}
                        isEnabled={isEnabled}
                        signInMethod={signInMethod}
                        onLink={this.onDefaultLoginLink}
                        onUnlink={this.onUnlink}/>
                    ) : (
                      <SocialLoginToggle
                        onlyOneLeft={onlyOneLeft}
                        isEnabled={isEnabled}
                        signInMethod={signInMethod}
                        onLink={this.onSocialLoginLink}
                        onUnlink={this.onUnlink}/>
                    )
                  }

                </ListItem>
              );
            })
          }
        </List>
        {error && error.message}
      </div>
    );
  }
}

class DefaultLoginToggle extends Component {
  constructor(props) {
    super(props);

    this.state = { passwordOne: '', passwordTwo: '' };
  }

  onSubmit = event => {
    event.preventDefault();

    this.props.onLink(this.state.passwordOne);
    this.setState({ passwordOne: '', passwordTwo: '' });
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      onlyOneLeft,
      isEnabled,
      signInMethod,
      onUnlink,
    } = this.props;

    const { passwordOne, passwordTwo } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return isEnabled ? (
      <Button
        type="button"
        onClick={() => this.onUnlink(signInMethod.id)}
        disabled={onlyOneLeft}>
        Deactivate {signInMethod.id}
      </Button>
    ) : (
      <form onSubmit={this.onSubmit}>
          <FormControl margin="normal" required>
            <InputLabel htmlFor="passwordOne">Password</InputLabel>
            <Input id="passwordOne" name="passwordOne" autoFocus type="password" onChange={this.onChange}/>
          </FormControl>
          <FormControl margin="normal" required>
            <InputLabel htmlFor="passwordTwo">Confirm New Password</InputLabel>
            <Input id="passwordTwo" name="passwordTwo" type="password" onChange={this.onChange}/>
          </FormControl>
          <Button
            type="submit"
            disabled={isInvalid}>
            Link {signInMethod.id}
          </Button>
      </form>
    )
  }
}


const SocialLoginToggle = ({
  onlyOneLeft,
  isEnabled,
  signInMethod,
  onLink,
  onUnlink,
}) =>
  isEnabled ? (
    <Button
      type="button"
      onClick={() => this.onUnlink(signInMethod.id)}
      disabled={onlyOneLeft}>
      Deactivate {signInMethod.id}
    </Button>
  ) : (
    <Button
      type="button"
      onClick={() => this.onLink(signInMethod.provider)}>
      Link {signInMethod.id}
    </Button>
  );
  
const LoginManagement = withFirebase(LoginManagementBase);

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withEmailVerification,
  )(AccountPage);
