import React from 'react';
import Button from '@material-ui/core/Button';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false }
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          { authUser => 
            needsEmailVerification(authUser) ? (
              <div>
                {this.state.isSent ? (
                <p>
                  E-Mail confirmation sent: Check your E-Mail
                  for a confirmation E-Mail. Refresh this page
                    once you have confirmed your E-Mail.
                </p>
                ) : (
                <p>
                  Verify your E-Mail: Check your E-Mail
                  for a confirmation E-Mail or send another
                  confirmation E-Mail.
                </p>
                )}
                <Button
                  type="button"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                  >
                  Send confirmatino E-Mail
                </Button>
              </div>
            ) : (
              <Component {...this.props} /> 
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

export default withEmailVerification;
