import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Chip from '@material-ui/core/Chip';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import FaceIcon from '@material-ui/icons/Face';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import EditIcon from '@material-ui/icons/Edit';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';

const styles = theme => ({
  card: {
    maxWidth: 400,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit,
  },
});

class Collectible extends Component {
  state = { 
    expanded: false
  };

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  handleDelete = () => {
      alert('You clicked the delete icon.'); // eslint-disable-line no-alert
  };

  handleClick = () => {
      alert('You clicked the Chip.'); // eslint-disable-line no-alert
  };

  render() {
    const { classes } = this.props;
    const { item } = this.props;

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label={item.name} className={classes.avatar}>
              {item.name.charAt(0)}
            </Avatar>
          }
          action={
              <IconButton>
                 <MoreVertIcon />
              </IconButton>
          }
          title={item.name}
        >
       </CardHeader>
       <CardMedia
         className={classes.media}
         image={require('../../images/' + item.image)}
         title={item.name}
       />
       <CardContent>
         <Typography component="p">
           Tags:
         </Typography>
         { item.tags.map(tag => (
             <Chip key={tag} label={tag} onClick={this.handleClick} className={classes.chip} />
           ))
         }
         { item.people.map(person => (
             <Chip key={person} avatar={<Avatar><FaceIcon/></Avatar>} label={person} onClick={this.handleClick} className={classes.chip} />
           ))
         }
       </CardContent>
       <CardActions className={classes.actions} disableActionSpacing>
         <IconButton aria-label="Edit Collectible" component={Link} to={'/detail/'+item.id}>
           <EditIcon />
         </IconButton>
         <IconButton aria-label="Share">
           <ShareIcon />
         </IconButton>
         <IconButton
           className={classnames(classes.expand, {
             [classes.expandOpen]: this.state.expanded,
           })}
           onClick={this.handleExpandClick}
           aria-expanded={this.state.expanded}
           aria-label="Show more"
         >
           <ExpandMoreIcon />
         </IconButton>
       </CardActions>
       <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
         <CardContent>
           <Typography paragraph>Description:</Typography>
           <Typography paragraph>
             {item.description}
           </Typography>
         </CardContent>
       </Collapse>
     </Card>
    );
  }
}

Collectible.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Collectible);
