import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  MenuButton,
  withStyles,
} from '@material-ui/core';
import { compose } from 'recompose';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Navigation from '../Navigation';
import LandingPage from '../Landing';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import DetailsPage from '../Details';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthentication } from '../Session';


class App extends React.Component {
  state = {
    anchorEl: null
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.props.firebase.doSignOut();
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
      return (
        <div>
          <CssBaseline/>
          <Router>
          <div>
            <AppBar position="sticky">
              <Toolbar>
                <IconButton color="inherit" aria-label="Home" component={Link} to={ROUTES.HOME}>
                  <HomeIcon />
                </IconButton>
                <Typography color="inherit">
                  Collector
                </Typography> 
                <IconButton
                  aria-owns={open ? 'menu-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit">
                    <AccountCircle/>
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={open}
                  onClose={this.handleClose}>
                  <AuthUserContext.Consumer>
                    {authUser =>
                       authUser ? 
                      (
                        <React.Fragment>
                          <MenuItem onClick={this.handleClose} component={Link} to={ROUTES.ACCOUNT}>My Account</MenuItem>
                          {!!authUser.roles[ROLES.ADMIN] && (
                            <MenuItem onClick={this.handleClose} component={Link} to={ROUTES.ADMIN}>Admin</MenuItem>
                          )}
                          <MenuItem onClick={this.handleClose} component={Link} to={ROUTES.LANDING}>Logout</MenuItem>
                        </React.Fragment>
                      ) :
                      (
                        <MenuItem onClick={this.handleClose} component={Link} to={ROUTES.SIGN_IN} >Login</MenuItem>
                      )
                    }
                  </AuthUserContext.Consumer>
                </Menu>
              </Toolbar>
            </AppBar>
              <Route exact path={ROUTES.LANDING} component={LandingPage} />
              <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
              <Route path={ROUTES.SIGN_IN} component={SignInPage} />
              <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
              <Route path={ROUTES.HOME} component={HomePage} />
              <Route path={ROUTES.ACCOUNT} component={AccountPage} />
              <Route path={ROUTES.ADMIN} component={AdminPage} />
              <Route path={ROUTES.DETAILS} component={DetailsPage} />
          </div>
          </Router>
        </div>
        );
  }
} 


export default compose(
  withFirebase,
  withAuthentication,
)(App);
