import React, { Component } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
  Paper,
  Button,
  Grid,
  Tabs,
  Tab,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';

import { withFirebase } from '../Firebase';
import { AuthUserContext, withAuthorization } from '../Session';

const styles = theme => ({
    layout: {
      width: 'auto',
      marginLeft: theme.spacing.unit * 2,
      marginRight: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 2 * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing.unit * 3,
      marginBottom: theme.spacing.unit * 3,
      padding: theme.spacing.unit * 2,
      [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
        marginTop: theme.spacing.unit * 6,
        marginBottom: theme.spacing.unit * 6,
        padding: theme.spacing.unit * 3,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    button: {
      marginTop: theme.spacing.unit * 3,
      marginLeft: theme.spacing.unit,
    },
});

class DetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      collectible: null,
      value: 0,
    };
  }

  onTabChange = (event, value) => {
    this.setState({ value });
  }

  render() {
    const { classes } = this.props;
    const { collectible, loading, value } = this.state;

    return (
      <React.Fragment>
      <main className={classes.layout}>
        <Paper className={classes.paper} elevation={1}>
          <Tabs
            value={value}
            onChange={this.onTabChange}
            indicatorColor="primary"
            textColor="primary">
            <Tab label="Details" />
            <Tab label="Pricing" />
            <Tab label="Locations" />
          </Tabs>
          {value === 0 && <DetailsForm/>}
          {value === 1 && <Typography>Pricing</Typography>}
          {value === 2 && <Typography>Locations</Typography>}
        </Paper>
      </main>
      </React.Fragment>
    );
  }

}

class DetailsFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      collectible: null,
      name: '',
      description: '',
      image: '',
      tags: [],
      people: [],
    };
  }

  onChangeText = event => {
    this.setState({ text: event.target.value }); 
  }

  onCreateCollectible = (event, authUser) => {
    this.props.firebase.collectibles().add({
      name: this.state.name,
      description: this.state.description,
      image: this.state.image,
      tags: this.state.tags,
      people: this.state.people,
      creator: authUser.uid,
    }).then(ref => {
      console.log('Added collectible with id: ', ref.id);
    });

    this.setState({
      name: '',
      description: '',
      image: '',
      tags: [],
      people: [],
    });

    event.preventDefault();
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { classes } = this.props;
    const { collectible, loading, name, description, image, tags, people } = this.state;
    const isInvalid = name === '';

    return (
      <React.Fragment>
      <AuthUserContext.Consumer>
      { authUser => (
        <form onSubmit={event => this.onCreateCollectible(event, authUser) }>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <TextField
                required
                id="name"
                name="name"
                label="Item Name"
                fullWidth
                onChange={this.onChange}
                autoComplete="name"/>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="description"
                label="Description"
                multiline
                rowsMax="4"
                fullWidth
                onChange={this.onChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="image"
                label="Image"
                onChange={this.onChange}
              />
            </Grid>
            <Grid item xs={6}/>
            <Grid item xs={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isInvalid}
                >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
        )}
      </AuthUserContext.Consumer>
      </React.Fragment>
    );
  }
}

const DetailsForm = withFirebase(DetailsFormBase);

const condition = authUser => !!authUser;

DetailsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(
  withFirebase,
  withAuthorization(condition),
  withStyles(styles),
)(DetailsPage)
