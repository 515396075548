import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  Grid,
  Typography,
  Fab,
  withStyles
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withAuthorization, withEmailVerification } from '../Session';
import Collectible from './Collectible';

const styles = theme => ({
layout: {                                                       
  width: 'auto',                                                
  marginLeft: theme.spacing.unit * 3,                           
  marginRight: theme.spacing.unit * 3,                          
  [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {  
    width: 1100,                                                
    marginLeft: 'auto',                                         
    marginRight: 'auto',                                        
  },                                                            
},                                                              
cardGrid: {                                                     
  padding: `${theme.spacing.unit * 8}px 0`,                     
},                                                              
card: {                                                         
  height: '100%',                                               
  display: 'flex',                                              
  flexDirection: 'column',                                      
},                                                              
cardMedia: {                                                    
  paddingTop: '56.25%', // 16:9                                 
},                                                              
cardContent: {                                                  
  flexGrow: 1,                                                  
},
fab: {
  margin: theme.spacing.unit,
  position: 'absolute',
  top: theme.spacing.unit * 2,
  right: theme.spacing.unit * 2,
},
extendedIcon: {
  marginRight: theme.spacing.unit,
},
});

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      collectibles: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe = this.props.firebase
      .collectibles()
      .onSnapshot(snapshot => {
        let collectibles = [];

        snapshot.forEach(doc =>
          collectibles.push({ ...doc.data(), uid: doc.id }),
          );

        this.setState({
          collectibles,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { classes } = this.props;
    const { collectibles, loading } = this.state;
    return(
      <div>
        {loading && <div>Loading...</div>}
        <main>
          <div className={classNames(classes.layout, classes.cardGrid)}>
            <Fab color="primary" aria-label="Add" className={classes.fab}>
              <AddIcon />
            </Fab>
            <Grid container spacing={40}>
              {collectibles.map(collectible => (
                <Grid item key={collectible.uid} sm={6} md={4} lg={3}>
                  <Collectible item={collectible}/>
                </Grid>
              ))}
            </Grid>
          </div>
        </main>
      </div>
    );
  }
}
HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const condition = authUser => !!authUser;

export default compose(
  withStyles(styles),
  withAuthorization(condition),
  withEmailVerification,
  )(HomePage);
